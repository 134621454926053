<template>
  <div>
    <table width="100%">
      <tbody>
      <!--tr-->
      <tr  :key="index1" v-for="(zar, index1) in ds_ajiliin_zar_view">
        <td>
          <div class="company_logos" v-if="zar.gishuun">
            <img class="logo" src="../../../assets/company_logo/1_gold.svg" alt="" v-if="zar.gishuun === 'Алтан багц'">
            <img class="logo" src="../../../assets/company_logo/1_silver.svg" alt="" v-if="zar.gishuun === 'Мөнгөн багц'">
            <img class="logo" src="../../../assets/company_logo/1_bronze.svg" alt="" v-if="zar.gishuun === 'Хүрэл багц'">
          </div>
          <div class="company_logos" v-else>
            <img class="logo" src="../../../assets/company_logo/2_gold.svg" alt="" v-if="zar.gishuun_bus === 'Алтан багц'">
            <img class="logo" src="../../../assets/company_logo/2_silver.svg" alt="" v-if="zar.gishuun_bus === 'Мөнгөн багц'">
            <img class="logo" src="../../../assets/company_logo/2_bronze.svg" alt="" v-if="zar.gishuun_bus === 'Хүрэл багц'">
          </div>
          <div class="p_logo">
            <img :src="IMAGE_URL + zar.logo" alt="" v-if="zar.logo != ''">
            <img src="../../../assets/no-image.png" alt="" v-else>
          </div>
        </td>
        <td>
          <div class="col_1 ">
            <router-link :to="`/b_a_z_details/${zar.id}`">
              <h5>{{ zar.ajiliin_torol }}</h5>
            </router-link>
            <div class="flex_col">
              <div class="sub_col_2">
                <span class="company_n">{{ zar.ner_mnbaiguullaga }}</span>
                <div class="text_bold">{{ 'where' | translate }}: <span
                    class="not_bold"> {{ zar.aimagname }} </span>
                </div>
              </div>
              <div class="static_button ">
                {{ zar.tsagiin_torol }}
              </div>
            </div>
          </div>
        </td>
        <td>
          <save_button_ajil_zar :zar_id="zar.id" type="baiguullaga"></save_button_ajil_zar>
          <div class="td_3">
            <div class="td_3_1">
              <p>{{ 'zarlasan_ognoo' | translate }}</p>
              <p class="diff_p_text">{{ zar.e_ognoo | date }}</p>
            </div>
            <div class="td_3_2">
              <p>{{ 'anket_avah_ognoo' | translate }}</p>
              <p class="p_text ">{{ zar.d_ognoo | date }}</p>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {GET_AJILIIN_ZAR_VIEW_LIMIT5, IMAGE_URL} from "../../../graphql/queries";
const save_button_ajil_zar = () => import(/* webpackChunkName: "save_button_ajil_zar" */ '../save_button_ajil_zar');
export default {
  name: "home_baiguullaga_ajil_zar",
  components: {
    "save_button_ajil_zar": save_button_ajil_zar,
  },
  data () {
    return {
      ds_ajiliin_zar_view: [],
      IMAGE_URL: IMAGE_URL,
    }
  },
  mounted() {
    this.get_baiguullaga_ajil_zar();
  },
  methods: {
    get_baiguullaga_ajil_zar() {
      this.$apollo.query({query: GET_AJILIIN_ZAR_VIEW_LIMIT5}).then(({data}) => {
        this.ds_ajiliin_zar_view = data.ds_ajiliin_zar_view;
      })
    },
  },
}
</script>

<style scoped>

</style>